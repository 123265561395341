import React from "react"

import Layout from "../../layouts/MainLayout/Layout"
import Seo from "../../config-components/seo"
import Map from "../../components/Map/Map"
import Feedback from "../../components/Feedback/Feedback"
import { Column, Contacts, Info, Wrapper } from "./PageContacts.styled"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"

const PageContacts = ({ location }) => (
  <Layout>
    <Header page={location.pathname} />
    <Contacts>
      <Seo
        title="Решили купить надувной катамаран? Наши контактные данные"
        description="Хотите купить водный велосипед MARINA-M для отдыха? Или нужен надувной катамаран для проката? Оставьте свой телефон, мы проконсультируем по всем вопросам."
        keywords="купить водный велосипед, купить катамаран, велокатамаран"
      />
      <Wrapper>
        <Feedback styles={{ marginBottom: "7rem" }} />
        <Info>
          <Column>
            <div>ООО «ПОЛИМЕРКОНСАЛТ»</div>
            <a href="tel:+79630843450">+7 (963) 084-34-50</a>
            <a href="mailto:info@katamaranov.net">info@katamaranov.net</a>
            <div>454091, г. Челябинск,</div>
            <div>ул. Степана Разина, д.3, офис 507/2</div>
          </Column>
          <Column>
            <div>ИНН 7451395876</div>
            <div>КПП 745101001</div>
            <div>ОГРН 1157451011740</div>
            <div>ОКПО 49095302</div>
            <div>ОКВЭД 74.14</div>
          </Column>
        </Info>
      </Wrapper>
      <Map />
    </Contacts>
    <Footer />
  </Layout>
)

export default PageContacts
