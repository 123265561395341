import styled from "styled-components"

export const Contacts = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  max-width: 754px;
  margin: 4rem auto 0 auto;
  @media screen and (max-width: 550px) {
    margin: 2rem 1.5rem 0 1.5rem;
  }
`

export const Info = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 754px;
  margin: 4rem auto;
  width: 100%;
  align-items: center;

  @media screen and (max-width: 530px) {
    flex-direction: column;
    margin: 2rem auto;
  }
`

export const Column = styled.div`
  text-align: center;
  color: #5a6578;
  line-height: 1.5;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    color: #5a6578;
  }

  @media screen and (max-width: 530px) {
    &:first-of-type {
      margin-bottom: 1.5rem;
    }
  }
`
