import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

const Map = () => {
  const query = useStaticQuery(graphql`
    query mapper {
      imageSharp(original: { src: { regex: "/mapper/" } }) {
        original {
          src
        }
      }
    }
  `)
  const iconUrl = query.imageSharp.original.src

  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    const DG = require("2gis-maps")
    const map = DG.map("map", {
      center: [55.136419, 61.40905],
      zoom: 16,
    })
    const icon = DG.icon({
      iconUrl: iconUrl,
      iconSize: [60, 60],
      iconAnchor: [30, 51],
    })

    DG.marker([55.136419, 61.40905], { icon }).addTo(map)
  }, [iconUrl])

  return (
    <div style={{ position: "relative" }}>
      <div id={`map`} style={{ width: "auto", height: 400 }} />
      {disabled && (
        <div
          onClick={() => setDisabled(false)}
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            background: "#00000045",
            cursor: "pointer",
          }}
        />
      )}
    </div>
  )
}

export default Map
